import React, { memo } from "react";

import Label from "astrid-components/lib/components/Inputs/Label";
import Switch from "astrid-components/lib/components/Inputs/Switch";
import { useConnection } from "astrid-helpers/src/peer";

import useText from "../../hooks/useText";
import { setSync, useSync } from "../../state/sync";

function onClick() {
	setSync((value) => !value);
}

function Sync() {
	const t = useText();
	const value = useSync();
	const connection = useConnection();

	return (
		<Label text={t("sync", "Sync")}>
			<Switch value={value} onClick={connection === "connected" ? onClick : undefined} />
		</Label>
	);
}

export default memo(Sync);
