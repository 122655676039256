import { debounce } from "debounce";
import React from "react";

import Pdf from "astrid-components/lib/components/Assets/Pdf";
import Modal from "astrid-components/lib/components/Modules/Modal";
import Text from "astrid-components/lib/components/Text/Text";
import createStore from "astrid-helpers/src/createStore";
import { isConnected } from "astrid-helpers/src/peer";

import * as firebase from "../helpers/firebase";
import selectMarker from "../helpers/selectMarker";

import { ads } from "./ads";
import { getRole } from "./permissions";
import { getProductionId, onProductionId } from "./productionId";
import { getSelectedMarker, onSelectedMarker } from "./selectedMarker";

export const [getSync, setSync, useSync, onSync] = createStore(false);

const getPath = () => `productions/${getProductionId()}/states/sync`;

const updateSync = debounce(() => {
	if (getSync() && isConnected()) {
		const selected = getSelectedMarker();
		const ad = selected && ads.get(selected[0]);

		firebase.worker.update(getPath(), {
			selectedMarker: getSelectedMarker(),
			scrollOffset: Pdf.getScrollOffset()[0],
			message: ad ? ad.text : false,
		});
	}
}, 500);

let prevMessage;

function updateReader({ sync, message, scrollOffset, selectedMarker }) {
	if (sync && getRole() === "reader") {
		if (scrollOffset) {
			Pdf.setScrollOffset([scrollOffset, true]);
		}

		if (selectedMarker) {
			selectMarker(...selectedMarker);
		}

		if (message && message !== prevMessage) {
			Modal.setContent(<Text style={{ fontSize: "3vh" }}>{message}</Text>);
		} else if (!message && Modal.getContent()) {
			Modal.setContent();
		}

		prevMessage = message;
	}
}

onSelectedMarker(updateSync);
Pdf.onScrollOffset(updateSync);

firebase.events.on("sync", ({ data }) => {
	if (data) {
		updateReader(data);
	} else {
		firebase.worker.set(getPath(), { sync: false, scrollOffset: 0 });
	}
});

onSync((sync) => {
	if (getRole() === "recorder") {
		firebase.worker.update(getPath(), { sync });
		updateSync();
	}
});

onProductionId((id) => {
	if (id) {
		firebase.worker.sync(id);
	}
});
