import createStore from "astrid-helpers/src/createStore";
import { getStreamFromInput } from "astrid-helpers/src/mediaDevices";
import { setStream } from "astrid-helpers/src/peer";

export const [getInput, setInput, useInput] = createStore("");

export async function updateInput(input) {
	setInput(input);

	const stream = await getStreamFromInput(input);

	if (stream) {
		setStream(stream);
	}
}
