import Pdf from "astrid-components/lib/components/Assets/Pdf";
import Timeline from "astrid-components/lib/components/Audio/Timeline";
import eventTarget from "astrid-helpers/src/eventTarget";
import msToTime from "astrid-helpers/src/msToTime";
import { messages, send } from "astrid-helpers/src/peer";
import { push } from "astrid-hooks/src/useHistory";

import alignEdit from "../helpers/alignEdit";
import enableAutoScroll from "../helpers/enableAutoScroll";
import * as firebase from "../helpers/firebase";
import * as master from "../helpers/master";
import { getAction, isAction, onAction, setAction } from "../state/action";
import { setBuffering } from "../state/buffering";
import { clips } from "../state/clips";
import { getFlattenedClips } from "../state/flattenedClips";
import { resetListenStop } from "../state/listen";
import { getRecorder, isRole } from "../state/permissions";
import {
	createPrecording,
	getLastPrecording,
	getLastPrecordingNumber,
	getPrecording,
	publishPrecording,
} from "../state/precordings";
import { getProduction } from "../state/production";
import { getProductionId } from "../state/productionId";
import { recordings } from "../state/recordings";
import { setSelectedClipId } from "../state/selectedClipId";
import { setShowRendered } from "../state/showRendered";
import { isStudioActive, setStudioConnect } from "../state/studio";
import { transactions } from "../state/transactions";

import { getChunk } from "./player";
import { autoAnimateViewToPosition, updatePosition } from "./timeline";

export const events = eventTarget();

export function startRecorder({ position, length, ...rest } = {}) {
	position = position || Timeline.getPosition();

	const { render } = getProduction();
	const modified = isRole("proofer") && render.modified;

	if (modified && modified < position) {
		return false;
	}

	setAction({
		...rest,
		position,
		type: "start",
		length: modified ? modified - position : length,
	});

	return events.until("stopped");
}

export function restartRecorder() {
	if (getRecorder() && isAction("start") && !getAction().recordPosition) {
		const position = Timeline.getPosition();

		const clip = getFlattenedClips().find(
			(clip) => clip.position <= position && position <= clip.position + clip.length,
		);

		const ms = clip ? clip.position + clip.length - position + 5 : 0;

		const timeout = setTimeout(() => {
			if (isAction("start")) {
				stopRecorder();
				startRecorder();
			}
		}, ms);

		onAction(() => clearTimeout(timeout), true);
	}
}

export function retakeRecording() {
	const action = getAction();
	const { position, recordPosition } = action;

	if (position && recordPosition && position < recordPosition) {
		const next = { ...action };
		const precording = getLastPrecording();

		if (precording?.clip) {
			const redoPrecording = precording.undo();
			const undoCommit = firebase.commit(precording.remove());

			next.undo = () => {
				const undoPrecording = redoPrecording?.();
				const redoCommit = undoCommit?.();

				return () => {
					undoPrecording?.();
					redoCommit?.();
				};
			};
		}

		startRecorder(next);
	}
}

export async function startEdit(range, preroll = 3000) {
	const [recordPosition] = range;
	const position = recordPosition - preroll;

	const promise = startRecorder({ position, recordPosition, range });
	const precording = getLastPrecording();

	await promise;

	return () => {
		if (precording?.clip) {
			precording.undo();
			firebase.commit(precording.remove());
		}
	};
}

export function stopRecorder(type = "stop") {
	setAction({ ...getAction(), type });

	return events.until("stopped");
}

export function pauseRecorder() {
	return stopRecorder("pause");
}

export function toggleRecorder() {
	if (isAction("stop") || isAction("pause")) {
		startRecorder();
	} else {
		stopRecorder();
		resetListenStop();
	}
}

// recording is ready in studio host
messages.on("ready", () => {
	setBuffering(false);
});

// recording started in studio host
messages.on("record", (number, { created } = {}) => {
	const action = getAction();
	const precording = getPrecording(number);

	setAction({ ...action, type: "record" });

	precording.findAlignTo();

	const clip = clips.create(number, {
		active: true,
		pageIn: Pdf.getPage() || 1,
		position: precording.recordPosition,
		start: precording.recordPosition - precording.startPosition,
	});

	const updates = [clip];

	if (!created) {
		const recording = recordings.create(number);

		updates.push(recording);
	}

	precording.id = clip.id;

	if (!precording.action.silent) {
		updates.push(transactions.create(clip.id, "create", msToTime(precording.recordPosition)));
		updates.push(master.modify(precording.recordPosition));
		Timeline.resetSize();
		enableAutoScroll();
	}

	firebase.commit(updates);

	push(
		"Ny inspelning",
		() => {
			const redoAction = action?.undo?.();
			const redoPrecording = precording.undo?.();
			const undoCommit = firebase.commit(precording.remove());

			return () => {
				redoAction?.();
				redoPrecording?.();
				undoCommit?.();
			};
		},
		(action) => action.type !== "record",
	);
});

// audio chunks from studio host
messages.on("peaks", async ({ number, peaks, length }) => {
	const precording = getPrecording(number);

	if (precording && !precording.deleted) {
		precording.append(peaks, length);

		publishPrecording(number);

		if (number === getLastPrecordingNumber()) {
			autoAnimateViewToPosition();

			const { clip } = precording;

			if (clip) {
				Timeline.setPosition(clip.position + precording.length - clip.start);

				// Trigger auto align of clip
				if (!precording.align && precording.alignTo && !precording.action.range && !precording.action.silent) {
					const dist = Timeline.getPosition() - precording.alignTo;

					if (dist > 4000) {
						precording.align = true;

						const updates = await precording.trim();

						if (!precording.moved && precording.alignTo) {
							updates.push(clip.update({ position: precording.alignTo - 100 }));
						}

						precording.undo = firebase.commit(updates);
					}
				}
			} else {
				if (precording.length >= getAction().length) {
					stopRecorder();
				}

				updatePosition(precording.startPosition + precording.length);
			}
		}
	}

	events.emit("peaks");
});

// recording stopped in studio host
messages.on("stop", async ({ number, length }) => {
	const precording = getPrecording(number);

	if (precording && !precording.deleted) {
		if (precording.length < 200) {
			firebase.commit(precording.remove());
		} else if (precording.id) {
			setSelectedClipId(precording.id);

			firebase.commit(
				precording.clips.map((clip) =>
					clip.update({
						active: false,
						end: clip.end || length,
						pageOut: Pdf.getPage() || 1,
					}),
				),
			);

			const { clip } = precording;

			if (!precording.action.silent && clip) {
				const { range } = precording.action;

				if (range) {
					precording.undo = firebase.commit(await alignEdit(clip, range));
				} else {
					const undoPrecording = precording.undo;
					const undoCommit = firebase.commit(precording.cutAround());

					precording.undo = () => {
						const redoPrecording = undoPrecording?.();
						const redoCommit = undoCommit?.();

						return () => {
							redoPrecording?.();
							redoCommit?.();
						};
					};
				}
			}
		}
	}

	events.emit("stopped", { length, number, precording });
});

messages.on("error", ({ code }) => {
	if (code === 1) {
		window.alert("Ljudet kunde ej hämtas från studion");
	}

	setStudioConnect(false);
});

onAction((action) => {
	const { type, position, recordPosition, length, silent = false } = action;

	switch (type) {
		case "start":
			if (isStudioActive()) {
				setBuffering(true);

				const precording = createPrecording(action);
				const data = { productionId: getProductionId(), number: precording.number };

				if (!silent) {
					data.queue = getChunk(position);
				}

				if (recordPosition) {
					data.time = Math.max(0, recordPosition - position);
					setShowRendered(false);
				}

				send("start", data);
			} else if (length) {
				const timeout = setTimeout(stopRecorder, length);

				return () => clearTimeout(timeout);
			}
			break;
		case "stop":
		case "pause":
			if (isStudioActive()) {
				setBuffering(false);
				send("stop");
			} else {
				events.emit("stopped");
			}
			break;
		default:
	}
});
