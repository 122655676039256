import React, { memo, useEffect, useState } from "react";

import Mic from "astrid-components/lib/components/Assets/Icons/Mic";
import MicOff from "astrid-components/lib/components/Assets/Icons/MicOff";
import Range from "astrid-components/lib/components/Inputs/Range";
import Dropdown from "astrid-components/lib/components/Modules/Dropdown";
import { send, useConnection } from "astrid-helpers/src/peer";

import useText from "../../hooks/useText";
import { useActionType } from "../../state/action";
import { setMuteSelf, useMuteSelf } from "../../state/muteSelf";
import { useMuteStudio } from "../../state/muteStudio";

import Button from "./Button";

function onClick() {
	setMuteSelf((muteSelf) => !muteSelf);
}

function MuteSelf() {
	const t = useText();
	const action = useActionType();
	const muteSelf = useMuteSelf();
	const connection = useConnection();
	const muteStudio = useMuteStudio();

	const [talkback, setTalkback] = useState(1);

	const active = !muteStudio && !muteSelf && action !== "record";

	useEffect(() => {
		if (connection === "connected") {
			send("talkback", muteSelf || muteStudio ? 0 : talkback);
		}
	}, [connection, muteSelf, muteStudio, talkback]);

	return (
		<Dropdown
			trigger={
				<Button
					label={!muteSelf ? t("talkbackAuto", "Talkback auto") : t("talkbackOff", "Talkback off")}
					active={!muteSelf}
					onClick={onClick}
				>
					{active ? <Mic /> : <MicOff />}
				</Button>
			}
		>
			<Range step={0.1} min={0} max={2} direction="to top" value={talkback} onChange={setTalkback} />
		</Dropdown>
	);
}

export default memo(MuteSelf);
