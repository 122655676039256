import Ads from "../classes/ads";

import { events, worker } from "../helpers/firebase";

import { onProductionId } from "./productionId";

export const ads = new Ads();

events.on("ads", (snapshot) => {
	if (snapshot.length > 0) {
		ads.snapshot = snapshot;
	} else {
		worker.set(`${ads.path}/pre`, {});
		worker.set(`${ads.path}/post`, {});
	}
});

onProductionId((id) => {
	if (id) {
		worker.ads(id);

		return () => ads.clear();
	}
});
