import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import { locizePlugin } from "locize";
import locizeLastUsed from "locize-lastused";
import { initReactI18next } from "react-i18next";

import { isLocal } from "astrid-helpers/src/env";

const promise = i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(locizePlugin)
	.use(locizeLastUsed)
	.init({
		debug: isLocal,
		defaultNS: "common",
		fallbackLng: "en",
		preload: ["en", "sv"],
		keyseparator: false,
		interpolation: {
			escapeValue: false,
		},
		backend: {
			referenceLng: "en",
			apiKey: "267fa677-3d34-407e-8f4a-61a21a8b71d3",
			projectId: "c44a5cf2-154e-4cba-ba67-95868adf7258",
			version: window.location.hostname === "studio.astrid.fm" ? "single-namespace-live" : "single-namespace",
		},
		react: {
			useSuspense: false,
		},
		saveMissing: isLocal,
		detection: {
			convertDetectedLanguage: (lng) => lng.split("-")[0],
		},
		locizeLastUsed: {
			debounceSubmit: isLocal ? 1000 * 60 : 1000 * 60 * 5,
			allowedHosts: ["localhost", "studio.astrid.fm", "beta.studio.astrid.fm", "stage.studio.astrid.fm"],
		},
	});

export const translations = {
	markerTypes: {
		edit: i18n.t("misreadMarker", "Misread"),
		missing: i18n.t("missingMarker", "Missing sentence(s)"),
		wrong: i18n.t("wrongMarker", "Mispronunciation"),
		script: i18n.t("scriptMarker", "Script error"),
		longer: i18n.t("longerMarker", "Pause too short"),
		shorter: i18n.t("shorterMarker", "Pause too long"),
		paragraph: i18n.t("paragraphMarker", "Long/Short section break"),
		chapter: i18n.t("chapterMarker", "Long/Short chapter break"),
		wrongPause: i18n.t("wrongPauseMarker", "Long/Short break"),
		memo: i18n.t("memoMarker", "Memo"),
		noise: i18n.t("noiseMarker", "Noise"),
		noisePause: i18n.t("noisePauseMarker", "Noise during silence"),
		noiseContent: i18n.t("noiseContentMarker", "Noise during sentence"),
	},
};

promise.then(() => {
	translations.markerTypesEn = {
		edit: i18n.t("misreadMarker", { lng: "en" }),
		missing: i18n.t("missingMarker", { lng: "en" }),
		wrong: i18n.t("wrongMarker", { lng: "en" }),
		script: i18n.t("scriptMarker", { lng: "en" }),
		longer: i18n.t("longerMarker", { lng: "en" }),
		shorter: i18n.t("shorterMarker", { lng: "en" }),
		paragraph: i18n.t("paragraphMarker", { lng: "en" }),
		chapter: i18n.t("chapterMarker", { lng: "en" }),
		wrongPause: i18n.t("wrongPauseMarker", { lng: "en" }),
		memo: i18n.t("memoMarker", { lng: "en" }),
		noise: i18n.t("noiseMarker", { lng: "en" }),
		noisePause: i18n.t("noisePauseMarker", { lng: "en" }),
		noiseContent: i18n.t("noiseContentMarker", { lng: "en" }),
	};
});

export default i18n;
