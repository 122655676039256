// import Pdf from "astrid-components/lib/components/Assets/Pdf";
// import Ad from "../classes/ad";
// export default function useAds() {
// 	const pages = Pdf.useNumPages();
// 	return [new Ad("pre", { text: "Påannons" }), new Ad("post", { text: "Avannons", page: pages })];
// }
import useEventState from "astrid-hooks/src/useEventState";

import { ads } from "../state/ads";

export default function useAds() {
	useEventState("update", ads.events);

	return ads;
}
