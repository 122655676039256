import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDrag } from "react-use-gesture";

import CheckIcon from "astrid-components/lib/components/Assets/Icons/Check";
import PauseIcon from "astrid-components/lib/components/Assets/Icons/Pause";
import PlayIcon from "astrid-components/lib/components/Assets/Icons/Play";
import RecordIcon from "astrid-components/lib/components/Assets/Icons/Record";
import RotateIcon from "astrid-components/lib/components/Assets/Icons/Rotate";
import TrashIcon from "astrid-components/lib/components/Assets/Icons/Trash";
import ZapIcon from "astrid-components/lib/components/Assets/Icons/Zap";
import Pdf from "astrid-components/lib/components/Assets/Pdf";
import List from "astrid-components/lib/components/Data/List";
import Button from "astrid-components/lib/components/Inputs/Button";
import Select from "astrid-components/lib/components/Inputs/Select";
import Flex from "astrid-components/lib/components/Layout/Flex";
import MouseLabel from "astrid-components/lib/components/Modules/MouseLabel";
import Text from "astrid-components/lib/components/Text/Text";
import context from "astrid-helpers/src/audioContext";
import createAudioPlayer from "astrid-helpers/src/audioPlayer";
import { languageOptions } from "astrid-helpers/src/languages";
import { isConnected, messages, send } from "astrid-helpers/src/peer";
import * as recorder from "astrid-helpers/src/recorder";
import useAsyncValue from "astrid-hooks/src/useAsyncValue";

import * as firebase from "../../helpers/firebase";
import useText from "../../hooks/useText";
import { isAction } from "../../state/action";
import { useProofer } from "../../state/permissions";
import { useProductionId } from "../../state/productionId";
import { setTool } from "../../state/tool";
import { setSelectedWord, useIsSelectedWord } from "../../state/word";

import WordTool from "../Tools/Word";

const player = createAudioPlayer(context);

player.connect();

function Word({ word }) {
	const ref = useRef();
	const wordRef = useRef(word);

	const [{ file, start, length }, setFile] = useState(word.getFile() || {});
	const [loading, setLoading] = useState(false);
	const [playing, setPlaying] = useState(false);
	const [recording, setRecording] = useState(false);

	const t = useText();
	const proofer = useProofer();
	const productionId = useProductionId();

	const [selected, autoPlay] = useIsSelectedWord(word.id);

	const contributions = Object.keys(word.contributions).filter(
		(key) => word.productions[word.contributions[key].production],
	);

	const [sound, soundLoading, soundError] = useAsyncValue(
		useCallback(() => player.createSoundFromUrl(file, start, length), [file, start, length]),
	);

	const canRecord = !loading && !playing;
	const canPlay = sound && !loading && !recording;

	const onDrag = useDrag(
		useCallback(
			({ tap, event }) => {
				event.stopPropagation();

				if (tap) {
					Pdf.setSearchIndex();

					if (selected) {
						setSelectedWord();
					} else {
						setSelectedWord(word.id);
					}
				}
			},
			[selected, word.id],
		),
	);

	const onDragPlay = useDrag(
		useCallback(
			({ tap, event }) => {
				event.stopPropagation();

				if (!canPlay) {
					return;
				}

				if (tap) {
					setPlaying((playing) => !playing);
				}
			},
			[canPlay],
		),
	);

	const onDragRecord = useDrag(
		useCallback(
			async ({ tap, first, event, movement }) => {
				event.stopPropagation();

				if (!canRecord) {
					return;
				}

				if (tap) {
					setRecording((recording) => !recording);
				} else if (!first && Math.abs(-movement[1]) > 30) {
					setTool(() => WordTool);
				}
			},
			[canRecord],
		),
	);

	const onChangeLanguage = useCallback(
		(language) => {
			firebase.commit(word.update({ [`productions.${productionId}.wordLanguage`]: language }));
		},
		[productionId, word],
	);

	const onClickChoose = useCallback(
		(key) => () => {
			firebase.commit(word.choose(key));
			setFile(word.getFile(key));
		},
		[word],
	);

	const onClickForvo = useCallback(() => {
		window.open(word.forvoUrl, "_blank");
	}, [word.forvoUrl]);

	const onClickDelete = useCallback(() => {
		firebase.commit(word.remove());
		setSelectedWord(null);
	}, [word]);

	useEffect(() => {
		if (selected) {
			ref.current?.scrollIntoViewIfNeeded?.();
		}
	}, [selected]);

	useEffect(() => {
		if (selected && autoPlay && file) {
			setPlaying(true);
			setSelectedWord(word.id, false);
		}
	}, [autoPlay, file, selected, word.id]);

	useEffect(() => {
		if (sound && playing) {
			const remote = isConnected() && !isAction("record");

			if (remote) {
				setLoading(true);
				send("word", { file, start, length });
				messages.once("loadedWord", () => setLoading(false));
				messages.once("stoppedWord", () => setPlaying(false));
			} else if (sound) {
				sound.once("stop", () => setPlaying(false));
				sound.play();
			}

			return () => {
				if (remote) {
					send("word");
				} else {
					sound.stop();
				}
			};
		}
	}, [playing, sound, file, start, length]);

	useEffect(() => {
		if (recording) {
			const word = wordRef.current;
			const { stop } = recorder.start();

			return () => {
				setLoading(true);
				setRecording(false);

				const blob = stop();

				setFile({ file: window.URL.createObjectURL(blob) });

				word.saveBlob(blob).then(() => {
					firebase.commit(word);
					setLoading(false);
				});
			};
		}
	}, [recording]);

	return (
		<List.Item ref={ref} active={selected}>
			<Flex cursor="pointer" height={45} padding="0 10px 0 20px" {...onDrag()}>
				<Text fontSize={14} fontWeight={600}>
					{word.word}
				</Text>
				<Flex marginLeft="auto">
					{selected && (
						<Button
							transparent
							size="small"
							color="negative"
							active={recording}
							disabled={!canRecord}
							{...onDragRecord()}
						>
							<RecordIcon size={14} animation={recording && "blink"} />
						</Button>
					)}

					{file && (
						<MouseLabel.Trigger label={!!soundError && t("errorMessageHeader")}>
							<Button
								transparent
								size="small"
								color={soundError ? "negative" : "positive"}
								active={!!soundError || playing}
								{...onDragPlay()}
							>
								{loading || soundLoading ? (
									<RotateIcon size={14} animation="rotate" />
								) : playing ? (
									<PauseIcon size={14} />
								) : soundError ? (
									<ZapIcon size={14} />
								) : (
									<PlayIcon size={14} />
								)}
							</Button>
						</MouseLabel.Trigger>
					)}
				</Flex>
			</Flex>

			{selected && (
				<div style={{ padding: "0 10px 10px 10px" }}>
					{contributions.length > 0 ? (
						<div>
							{contributions.map((key) => {
								const { production } = word.contributions[key];
								const { wordLanguage } = word.productions[production] || {};

								return (
									<Flex key={key} display="inline-flex">
										<Button
											key={key}
											transparent
											size="small"
											active={key === word.choice}
											color={key === word.choice ? "positive" : "default"}
											onClick={onClickChoose(key)}
										>
											{word.language(key)}
											{key === word.choice && (
												<>
													&nbsp;
													<CheckIcon size={14} />
												</>
											)}
										</Button>

										{production === productionId && (
											<Select
												search
												value={wordLanguage}
												options={languageOptions.map((o) => ({ ...o, label: o.text }))}
												placeholder="Välj språk"
												onChange={onChangeLanguage}
											>
												<span />
											</Select>
										)}
									</Flex>
								);
							})}
						</div>
					) : (
						<Text padding="0 0 10px 10px">
							{t("noTranslations", "There are no pronunciations for this word")}
						</Text>
					)}

					{proofer && (
						<Flex>
							<Flex marginLeft="auto">
								<Button transparent size="small" onClick={onClickForvo}>
									Forvo
								</Button>
								<Button transparent size="small" color="negative" onClick={onClickDelete}>
									<TrashIcon size={14} />
								</Button>
							</Flex>
						</Flex>
					)}
				</div>
			)}
		</List.Item>
	);
}

export default memo(Word);
