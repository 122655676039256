import createStore from "./createStore";

export const [getStream, setStream, useStream] = createStore();
export const [getInputs, setInputs, useInputs] = createStore([]);
export const [getOutputs, setOutputs, useOutputs] = createStore([]);

const mapDevice = ({ deviceId, label }) => ({
	key: deviceId,
	value: deviceId,
	label,
});

export async function getStreamFromInput(input) {
	const stream = getStream();

	if (stream) {
		for (const track of stream.getTracks()) {
			track.stop();
			stream.removeTrack(track);
		}
	}

	if (navigator.mediaDevices) {
		const stream = await navigator.mediaDevices.getUserMedia({
			audio: {
				deviceId: input || "default",
				autoGainControl: false,
				echoCancellation: false,
				noiseSuppression: false,
				sampleRate: { min: 44100 },
			},
			video: false,
		});

		setStream(stream);

		if (getInputs().length === 0) {
			const devices = await navigator.mediaDevices.enumerateDevices();

			setInputs(devices.filter(({ kind }) => kind === "audioinput").map(mapDevice));
			setOutputs(devices.filter(({ kind }) => kind === "audiooutput").map(mapDevice));
		}

		return stream;
	}
}
