import uniqid from "uniqid";

import { audioBufferFromURL } from "./audioBuffer";
import clamp from "./clamp";
import eventTarget from "./eventTarget";
import raf from "./raf";

export default function createAudioPlayer(context, { zero = 0.0001, minFade = 0.0005 } = {}) {
	const register = {};

	const destination = context.createGain();

	const state = {
		volume: 1,
		currentPosition: 0,
	};

	const resume = () => {
		if (context.state !== "running") {
			return context.resume();
		}

		return Promise.resolve();
	};

	const connect = () => {
		destination.connect(context.destination);

		return () => destination.connect(context.destination);
	};

	const setVolume = (volume = 1) => {
		state.volume = volume;
	};

	const createSound = ({ buffer, start = 0, length = 0, volume = 1, fadein = 0, fadeout = 0, position = 0 }) => {
		const id = uniqid();
		const events = eventTarget();

		start = start / 1000;
		length = length / 1000;
		fadein = fadein / 1000;
		fadeout = fadeout / 1000;
		position = position / 1000;

		const offset = Math.abs(Math.min(0, position));

		volume = Math.max(zero, volume * state.volume);

		const sound = {
			...events,
			id,
			stop: () => {
				events.emit("stop", this);
			},
			play() {
				position = Math.max(0, position);
				length = Math.max(0, length - offset);

				if (buffer && length > 0) {
					const gain = context.createGain();
					const source = context.createBufferSource();

					gain.gain.value = 0;

					source.buffer = buffer;

					const startVolume =
						offset < fadein
							? clamp((offset / fadein) * volume, zero, volume)
							: length < fadeout
							? clamp((length / fadeout) * volume, zero, volume)
							: zero;

					const peakVolume = length < fadeout ? startVolume : volume;

					start = Math.max(0, start + offset);
					fadein = Math.max(minFade, fadein - offset);
					fadeout = Math.min(fadeout, length);

					source.connect(gain);
					gain.connect(destination);

					const { currentTime } = context;

					gain.gain.setValueAtTime(startVolume, currentTime + position);
					gain.gain.linearRampToValueAtTime(peakVolume, currentTime + position + fadein);
					gain.gain.setValueAtTime(peakVolume, currentTime + position + (length - fadeout));
					gain.gain.linearRampToValueAtTime(zero, currentTime + position + length);

					this.stop = (fadeout = 25) => {
						if (register[id]) {
							events.emit("stop", this);

							delete register[id];

							gain.gain.cancelScheduledValues(context.currentTime);

							if (fadeout > 0) {
								gain.gain.setValueAtTime(gain.gain.value, context.currentTime);
								gain.gain.linearRampToValueAtTime(zero, context.currentTime + fadeout / 1000);
							}

							setTimeout(() => {
								source.stop();

								gain.disconnect();
								source.disconnect();
							}, fadeout + 25);
						}
					};

					this.promise = new Promise((resolve) => {
						source.onended = () => {
							events.emit("ended", this);

							this.stop(0);
							resolve();
						};
					});

					source.start(currentTime + position, start, length);

					events.emit("start", this);

					return (register[id] = this);
				}
			},
		};

		return sound;
	};

	const createSoundFromUrl = async (url, start = 0, length, options) => {
		const buffer = await audioBufferFromURL(url);

		return createSound({
			start,
			buffer,
			length: length || buffer.duration * 1000,
			...options,
		});
	};

	const createSounds = (array) => array.filter(({ buffer }) => buffer).map(createSound);

	const stopSounds = (sounds) => (sounds || Object.values(register)).map((sound) => sound.stop());

	const playSounds = (sounds) => {
		const events = eventTarget();
		const startTime = context.currentTime || 0;

		const getTime = () => (context.currentTime - startTime) * 1000;

		const unraf = raf(() => {
			events.emit("time", getTime());
		}, 50);

		const promise = Promise.all(sounds.map((sound) => sound.play().until("stop"))).then(() => {
			unraf();
			events.emit("stop", getTime());
			events.clear();
		});

		return {
			...events,
			promise,
			startTime,
			stopSounds: () => stopSounds(sounds),
		};
	};

	return {
		resume,
		connect,
		destination,
		setVolume,
		createSound,
		createSounds,
		createSoundFromUrl,
		playSounds,
		stopSounds,
	};
}
