import React, { memo } from "react";

import { useIncomingStream } from "astrid-helpers/src/peer";
import useAudio from "astrid-hooks/src/useAudio";

import { useMuteStudio } from "../../state/muteStudio";
import { useOutput } from "../../state/output";

function Audio() {
	const output = useOutput();
	const mute = useMuteStudio();
	const stream = useIncomingStream();

	const audio = useAudio({
		mute,
		output,
		stream,
	});

	return <audio ref={audio} />;
}

export default memo(Audio);
