export default function eventTarget() {
	let listeners = {};

	const off = (event, listener) => {
		if (listeners[event]) {
			listeners[event] = listeners[event].filter((a) => a !== listener);
		}
	};

	const on = (event, listener, first = false) => {
		listeners[event] = first ? [listener, ...(listeners[event] || [])] : [...(listeners[event] || []), listener];

		return () => off(event, listener);
	};

	const once = (event, listener, first) => {
		const handler = (...payload) => {
			off(event, handler);
			listener(...payload);
		};

		on(event, handler, first);

		return () => off(event, handler);
	};

	const emit = (event, ...payload) => {
		if (listeners[event]) {
			for (const listener of listeners[event]) {
				listener(...payload);
			}
		}
	};

	const until = (event) =>
		new Promise((resolve, reject) => {
			once(event, (data) => {
				if (data !== "abort") {
					resolve(data);
				} else {
					reject(data);
				}
			});
		});

	const abort = (event) => emit(event, "abort");

	const clear = () => {
		listeners = {};
	};

	const getListeners = (event) => listeners[event];

	return { on, once, off, emit, until, abort, clear, getListeners };
}
