import createStore from "astrid-helpers/src/createStore";
import { isConnected, useConnection } from "astrid-helpers/src/peer";

import { getMuteStudio, useMuteStudio } from "./muteStudio";

export const [getStudioId, setStudioId, useStudioId, onStudioId] = createStore();
export const [getStudioConnect, setStudioConnect, useStudioConnect, onStudioConnect] = createStore();

export function isStudioActive() {
	const muteStudio = getMuteStudio();

	return isConnected() && !muteStudio;
}

export function useStudioActive() {
	const connected = useConnection();
	const muteStudio = useMuteStudio();

	return connected === "connected" && !muteStudio;
}
