import context from "./audioContext";
import { getStream } from "./mediaDevices";

let source;
let encoder;

function getSource() {
	if (!source) {
		source = context.createMediaStreamSource(getStream());
	}

	return source;
}

function getEncoder() {
	if (!encoder) {
		encoder = new window.lamejs.Mp3Encoder(1, context.sampleRate || 48000, 192);
	}

	return encoder;
}

function floatTo16BitPCM(input, output) {
	for (let i = 0; i < input.length; i++) {
		const s = Math.max(-1, Math.min(1, input[i]));
		output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
	}
}

function convertBuffer(arrayBuffer) {
	const data = new Float32Array(arrayBuffer);
	const out = new Int16Array(arrayBuffer.length);

	floatTo16BitPCM(data, out);

	return out;
}

function encode(data, arrayBuffer) {
	const encoder = getEncoder();

	const maxSamples = 1152;
	const samplesMono = convertBuffer(arrayBuffer);

	let remaining = samplesMono.length;

	for (let i = 0; remaining >= 0; i += maxSamples) {
		data.push(encoder.encodeBuffer(samplesMono.subarray(i, i + maxSamples)));

		remaining -= maxSamples;
	}
}

export function start() {
	const data = [];
	const source = getSource();
	const processor = context.createScriptProcessor(4096, 1, 1);

	source.connect(processor);
	processor.connect(context.destination);
	processor.onaudioprocess = (event) => encode(data, event.inputBuffer.getChannelData(0));

	return {
		stop: () => {
			source.disconnect(processor);
			processor.disconnect(context.destination);

			const buffer = encoder.flush();

			if (buffer.length > 0) {
				data.push(buffer);
			}

			return new Blob(data, { type: "audio/mp3" });
		},
	};
}
