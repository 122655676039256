import React, { memo, useCallback, useEffect } from "react";

import Phone from "astrid-components/lib/components/Assets/Icons/Phone";
import Button from "astrid-components/lib/components/Inputs/Button";
import Modal from "astrid-components/lib/components/Modules/Modal";
import Text from "astrid-components/lib/components/Text/Text";
import context from "astrid-helpers/src/audioContext";
import { connect, disconnect, getConnection, sendSignal, useConnection } from "astrid-helpers/src/peer";
import useText from "astrid-studio-web/src/hooks/useText";

import * as firebase from "../../helpers/firebase";
import { useAdmin } from "../../state/permissions";
import { useSession } from "../../state/session";
import { setShowRendered } from "../../state/showRendered";
import { getStudioId, setStudioConnect, useStudioConnect, useStudioId } from "../../state/studio";
import { studios } from "../../state/studios";
import { useProfile, useUid } from "../../state/user";

import Session from "./Session";

firebase.events.on("signal", (signal) => sendSignal(signal));

function updateStudio(data) {
	const studioId = getStudioId();

	if (studioId) {
		return firebase.worker.update(`studios/${studioId}`, data);
	}
}

function Connection() {
	const t = useText();

	const uid = useUid();
	const user = useProfile();
	const admin = useAdmin();
	const session = useSession();
	const connection = useConnection();
	const studioConnect = useStudioConnect();

	const studio = studios.useById(useStudioId());

	const id = studio && studio.id;
	const connecting = connection === "connect";
	const connected = connection === "connected";
	const occupied = studio && studio.status === "occupied";
	const canConnect = studio && !occupied;
	const canDisconnect = connected || (occupied && admin);
	const disabled = !canDisconnect && !canConnect;

	const onClick = useCallback(() => {
		context.resume();
		Modal.setContent();

		if (getConnection()) {
			setStudioConnect(false);
		} else if (occupied) {
			updateStudio({ hangup: true });
		} else {
			setShowRendered(false);
			setStudioConnect(true);
		}
	}, [occupied]);

	useEffect(() => {
		if (connected) {
			return () => setStudioConnect(false);
		}
	}, [connected]);

	useEffect(() => {
		if (id && connecting) {
			firebase.worker.signals(id);

			return () => {
				firebase.worker.signals();
			};
		}
	}, [connecting, id]);

	useEffect(() => {
		if (id && studioConnect) {
			updateStudio({ uid, user: user?.email });

			connect({
				initiator: true,
				onSignal: (signal) => firebase.worker.signal(id, signal),
			});

			return () => {
				disconnect();
			};
		}
	}, [studioConnect, id, uid, user?.email]);

	useEffect(() => {
		if (session && session.data.finish && !connected) {
			Modal.setContent(<Session onReconnect={onClick} />);
		}
	}, [connected, session, onClick]);

	useEffect(() => {
		if (session && session.data.status === "cancelled") {
			Modal.setContent(
				<>
					<Text>{t("sessionCancelled", "The session was cancelled.")}</Text>
					<br />
					<Button transparent size="small" onClick={() => Modal.setContent()}>
						{t("close", "Close")}
					</Button>
				</>,
			);
		}
	}, [connected, session, onClick, t]);

	useEffect(() => {
		if (session && session.data.status === "done") {
			Modal.setContent(
				<>
					<Text>{t("sessionDone", "The session is done.")}</Text>
					<br />
					<Button transparent size="small" onClick={() => Modal.setContent()}>
						{t("close", "Close")}
					</Button>
				</>,
			);
		}
	}, [connected, session, onClick, t]);

	return (
		<Button
			transparent
			disabled={disabled}
			active={connecting || connected}
			color={canDisconnect ? "negative" : "positive"}
			onClick={onClick}
		>
			<Phone animation={connecting && "ring"} style={{ transform: canDisconnect && "rotate(135deg)" }} />
		</Button>
	);
}

export default memo(Connection);
