import Peer from "simple-peer/simplepeer.min.js";
import "webrtc-adapter";

import getIceServers from "astrid-config/src/iceServers";

import createStore from "./createStore";
import eventTarget from "./eventTarget";

export const messages = eventTarget();

export const [getConnection, setConnection, useConnection, onConnection] = createStore();
export const [getIncomingStream, setIncomingStream, useIncomingStream] = createStore();

const state = {
	peer: null,
	stream: undefined,
};

function createPeer(options) {
	const { initiator = false, onSignal, port = 3478 } = options;

	disconnect();

	setConnection("connect");

	const peer = new Peer({
		initiator,
		trickle: false,
		stream: state.stream,
		config: {
			iceServers: getIceServers(port),
		},
	});

	console.log("Created peer", peer._id);

	peer.on("data", (json) => {
		const { key, value, data } = JSON.parse(json);

		messages.emit(key, value, data);
	});

	peer.on("connect", () => {
		console.log("Connected to peer", peer._id);

		peer._channel.onmessage = ({ data }) => {
			if (typeof data === "string") {
				const { key, value } = JSON.parse(data);
				messages.emit(key, value);
			} else {
				messages.emit("data", data);
			}
		};

		setConnection("connected");
	});

	peer.on("iceStateChange", (state) => {
		if (state === "disconnected") {
			console.log("Connection disconnected for peer", peer._id);

			if (peer._id === state.peer?._id) {
				disconnect();
			}
		}
	});

	peer.on("close", () => {
		console.log("Connection closed for peer", peer._id);

		if (peer._id === state.peer?._id) {
			disconnect();
		}
	});

	peer.on("error", (e) => {
		console.error(e);

		if (peer._id === state.peer?._id) {
			disconnect();
		}
	});

	peer.on("stream", setIncomingStream);

	peer.on("signal", (data) => onSignal(JSON.stringify(data)));

	return peer;
}

export function isConnected() {
	return getConnection() === "connected";
}

export function useIsConnected() {
	return useConnection() === "connected";
}

export function setStream(stream) {
	state.stream = stream;
}

export function disconnect() {
	if (state.peer && !state.peer.destroyed) {
		console.log("Destroying peer", state.peer._id);

		state.peer.destroy();
	}

	state.peer = null;

	setConnection();
}

export function connect({ initiator, stream, onSignal }) {
	disconnect();

	state.sentSignals = [];
	state.stream = stream || state.stream;
	state.peer = createPeer({ initiator, onSignal });

	return state.peer;
}

export function sendSignal(signal) {
	if (!state.sentSignals.includes(signal.signal)) {
		state.sentSignals.push(signal.signal);

		if (signal.signal && state.peer && !state.peer.destroyed) {
			state.peer.signal(JSON.parse(signal.signal));
		}
	}
}

export function send(key, value = null, data = {}) {
	if (state.peer && !state.peer.destroyed) {
		if (typeof key !== "string") {
			state.peer.send(key);
		} else {
			state.peer.send(JSON.stringify({ key, value, data }));
		}
	}
}
