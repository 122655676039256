import { useEffect, useState } from "react";
import useMountedState from "react-use/lib/useMountedState";

export default function useEventState(event, target, initialState, debug = false) {
	const isMounted = useMountedState();
	const [state, setState] = useState(initialState);

	useEffect(() => {
		return target.on(event, (state) => {
			if (isMounted()) {
				if (debug) {
					console.log("useEventState", event, state);
				}

				setState(() => state);
			}
		});
	}, [target, event, isMounted]);

	return state;
}
