import React, { useCallback, useEffect, useMemo } from "react";

import Button from "astrid-components/lib/components/Inputs/Button";
import Select from "astrid-components/lib/components/Inputs/Select";
import Modal from "astrid-components/lib/components/Modules/Modal";
import Header from "astrid-components/lib/components/Text/Header";
import Text from "astrid-components/lib/components/Text/Text";
import { send, useConnection } from "astrid-helpers/src/peer";

import useProductionsOptions from "../../hooks/useProductionsOptions";
import useText from "../../hooks/useText";
import { useAdmin } from "../../state/permissions";
import { useProduction } from "../../state/production";
import { setProductionId, useProductionId } from "../../state/productionId";
import { useSession } from "../../state/session";

const style = { fontSize: 12, fontWeight: 400, lineHeight: 1.2 };

function Production() {
	const t = useText();
	const admin = useAdmin();
	const session = useSession();
	const production = useProduction();
	const connection = useConnection();
	const productionId = useProductionId();
	const productions = useProductionsOptions();

	const selected = useMemo(
		() => (
			<div>
				<Header color="primary" style={style}>
					{t("production", "Production")}
				</Header>
				<Header>{production ? production.title : t("selectProduction", "Select production")}</Header>
			</div>
		),
		[production, t],
	);

	const onChange = useCallback(
		(productionId) => {
			if (session) {
				Modal.setContent(
					<>
						<Text>
							{t(
								"sessionInProgressDescription",
								"You can not change production while a session is in progress.",
							)}
						</Text>
						<br />
						<Button transparent size="small" onClick={() => Modal.setContent()}>
							{t("close", "Close")}
						</Button>
					</>,
				);
			} else {
				setProductionId(productionId);
			}
		},
		[session, t],
	);

	useEffect(() => {
		if (productionId && connection === "connected") {
			send("production", productionId);
		}
	}, [productionId, connection]);

	return admin || !production ? (
		<Select
			search
			value={productionId}
			options={productions}
			placeholder={t("searchProduction", "Search production")}
			onChange={onChange}
		>
			{selected}
		</Select>
	) : (
		selected
	);
}

export default Production;
