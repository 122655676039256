import styled from "styled-components";

import useText from "../../hooks/useText";

const Container = styled.div`
	width: 400px;
	position: absolute;
	right: 20px;
	bottom: 20px;
	background-color: red;
	border-radius: 5px;
	padding: 20px;
	text-align: center;
	color: white;
`;

const Text = styled.p`
	font-size: 15px;
	margin: 0;
`;

export default function OfflinePopup() {
	const t = useText();

	return (
		<Container>
			<Text>{t("connectionLost", "Internet connection was lost!")}</Text>
		</Container>
	);
}
