import React, { memo, useMemo } from "react";

import Divider from "astrid-components/lib/components/Layout/Divider";
import Flex from "astrid-components/lib/components/Layout/Flex";
import Panel from "astrid-components/lib/components/Layout/Panel";
import Header from "astrid-components/lib/components/Text/Header";

import useText from "../../hooks/useText";
import { useAdmin } from "../../state/permissions";
import { useStudioId } from "../../state/studio";
import { studios } from "../../state/studios";

import Connect from "./Connect";
import SelectStudio from "./SelectStudio";

const style = { fontSize: 12, fontWeight: 400, lineHeight: 1.2 };

function Studio() {
	const t = useText();
	const admin = useAdmin();
	const studioId = useStudioId();
	const studio = studios.useById(studioId);

	const selected = useMemo(
		() => (
			<div>
				<Header color="primary" style={style}>
					{t("studio", "Studio")}
				</Header>
				<Header>{studio ? studio.label : t("selectStudio", "Select studio")}</Header>
			</div>
		),
		[studio, t],
	);

	return (
		<Panel style={{ overlflow: "visible" }}>
			<Flex alignItems="stretch">
				<Flex flex={1} padding="8px 20px">
					{admin || !studio ? <SelectStudio>{selected}</SelectStudio> : selected}
				</Flex>
				<Divider vertical />
				<Flex padding={8}>
					<Connect />
				</Flex>
			</Flex>
		</Panel>
	);
}

export default memo(Studio);
