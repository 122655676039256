import Timeline from "astrid-components/lib/components/Audio/Timeline";

import useParts from "./useParts";

export default function useScopeParts() {
	const parts = useParts();
	const scope = Timeline.useScope();

	return parts.scope(scope);
}
